<template>
  <div class="activity">
    <div class="activity-top" :class="{is_swiper:!(swiperList&&swiperList.length)}">
      <gSwiperCommon :bannerType="'show.page.home.pc.en'" :bannerHeight="400"></gSwiperCommon>
      <!-- <gSwiper :list="list" v-if="list.length" :navigation="false"></gSwiper> -->
    </div>
    <div class="g-content">
      <div class="activity-live" v-if="swiperList.length">
        <div class="al-l">
          <swiper class="my-swiper gallery" :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide v-for="(item, index) in swiperList" :key="'gSwiperCommon-' + index">
              <img :src="item.banners[0]" v-if="item.banners && item.banners[0]" class="swiper-cover" @click="LinkAct(item)" />
              <img src="@assets/img/empty.png" v-else class="swiper-cover" />
              <p class=l-banner-bot>
                <span>{{item.title}}</span>
                <span>{{item.startTime | moment('YYYY/MM/DD HH:mm')}} ~ {{item.endTime | moment('YYYY/MM/DD HH:mm')}}</span>
              </p>
            </swiper-slide>
          </swiper>
        </div>
        <div class="al-r">
          <div class="hide-scrollbar">
            <div class="my-swiper thumbs">
              <div class="swiper-slide" v-for="(item, index) in swiperList" :key="'gSwiperCommon2-' + index" :class="swiperIndex == index ? 'swiper-slide-active' : ''" style="margin-bottom: 10px;"
                @click="selectSwiper(index)">
                <img :src="item.banners[0]" v-if="item.banners && item.banners[0]" class="swiper-cover" v-load="'oActSmall'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="activity-tab">
        <div class="activity-tab-item" v-for="(item,index) in type" :key="index" @click="selectTab(index)" :class="activeIndex == index ? 's-primary' :''">
          {{item}}
        </div>
      </div>
      <div class="activity-list">
        <Row :gutter="20">
          <Col span="6" v-for="(item, index) in ActList" :key="'act-' + index">
          <div class="m-list5 f-csp" @click="LinkAct(item)">
            <div class="ml5-brand">
              <span class="u-brand2" v-if="item.hasLive && item.liveAuditState === 'PASS' && item.liveStateIndex == 1">Live</span>
            </div>
            <div class="ml5-t">
              <img :src="item.banners[0]" class="u-img-100" v-load="'oActSmall'" />
            </div>
            <div class="ml5-b">
              <p class="ml5-txt4">{{item.title}}</p>
              <p class="ml5-txt2">{{item.startTime | moment('YYYY/MM/DD HH:mm')}} ~ {{item.endTime | moment('YYYY/MM/DD HH:mm')}}</p>
              <p class="ml5-txt2 f-toe">{{item.address}}</p>
              <p class="ml5-txt3">
              <div class="u-icon" style="color:#999;width:100%;">
                <i class="icon iconfont icon-liulanliang"></i>
                <span class="u-icon-txt">{{item.viewCount}}</span>
                <i class="icon iconfont icon-ren"></i>
                <span class="u-icon-txt">{{item.followCount}}</span>
              </div>
              </p>
            </div>
          </div>
          </Col>
        </Row>
      </div>
      <div class="u-empty" v-if="dataLoading">{{ $t("lang.loading") }}</div>
      <div class="u-empty" v-if="!dataLoading && !ActList.length">{{ $t("lang.No_data") }}</div>
      <div class="my-page" v-if="totalCount > pageSize">
        <Page :current="pageIndex" :page-size="pageSize" :total="totalCount" @on-change="changePage" @on-page-size-change="changePageSize" show-elevator show-total />
      </div>
      <!-- <div class="no_more" v-if="finished">
        <p>{{$t('lang.Its_the_end')}}</p>
      </div>
      <div class="more" @click="moreClick" v-else>
        <p>{{$t('lang.loadMore')}}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import gSwiper from "@components/gSwiper/gSwiper.vue";
import "swiper/dist/css/swiper.css";
import { mapGetters, mapActions } from "vuex";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
export default {
  name: "activity",
  components: {
    gSwiper,
    gSwiperCommon,
  },
  data() {
    const that = this;
    return {
      list: [
        "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2634306427,1511025022&fm=26&gp=0.jpg",
      ],
      swiperIndex: 0,
      swiperOptionTop: {
        autoplay: true, //等同于以下设置
        // loop: true,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 10,
        on: {
          slideChange() {
            that.swiperIndex = this.realIndex;
          },
        },
      },
      swiperOptionThumbs: {
        direction: "vertical",
        // loop: true,
        loopedSlides: 4, // looped slides should be the same
        spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        // autoplay: true,
      },

      exActList: [], // 主题活动
      where: {},
      order: [],
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      swiperList: [], // 轮播的数据
      ActList: [], // 活动列表的数据
      bannerList: [], // 前3个活动数据
      finished: false, // 加载更多
      type: ["All", "Underway", "Preparing"], //,'有直播'
      activeIndex: 0,
      dataLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      searchType: "getSearchType",
      searchTxt: "getSearchTxt",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      userInfo: "getUser",
      activityPage: "getActivityPage",
    }),
  },
  watch: {
    searchTxt(val, oldVal) {
      this.$store.commit("setActivityPage", 1);
      this.pageIndex = 1;
      this.init();
    },
  },
  created() {
    if (this.activityPage) {
      this.pageIndex = this.activityPage;
    }
    this.init();
    document.title = this.exhibitionInfo
      ? this.exhibitionInfo.nameEn
      : "ExShow";
  },
  methods: {
    ...mapActions({
      graphqlPostByZXS: "graphqlPostByZXS",
      graphqlPostByZXSEn: "graphqlPostByZXSEn",
      LinkAct: "linkTo/LinkAct",
      getShareConfig: "share/getShareConfig",
    }),
    selectSwiper(index) {
      this.$refs.swiperTop.swiper.slideTo(index, 1000, false);
    },
    changePage(e) {
      this.$store.commit("setActivityPage", e);
      this.pageIndex = e;
      this.getActiveList();
    },
    changePageSize(e) {
      this.pageSize = e;
    },
    async setShare() {
      let data = await this.getShareConfig({
        id: this.exhibitionId,
        name: "share.exhibition.config",
      });
      let share = {
        url: location.href,
        title: "活动列表-" + this.exhibitionInfo.name,
        desc: "",
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    init() {
      this.setShare();
      this.where = {
        equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        equal_isExhibitionActivity: { n: "isExhibitionActivity", v: 0 },
        equal_isShow: { n: "isShow", v: 1 },
      };
      this.order = [
        { n: "Topic", v: 0 },
        { n: "TopicIndex", v: 0 },
        { n: "StateIndex", v: 1 },
        { n: "startTime", v: 1 },
        { n: "endTime", v: 0 },
        { n: "ViewCount", v: 0 },
        { n: "CreateAt", v: 0 },
      ];
      // this.getExActive(); // 获取展会活动(主题活动)
      this.getCommAct();
    },
    //获取推荐的活动
    async getCommAct() {
      let query = `
       query activityQuery($where:_Any!,$orders:[_Any]){
            activityQuery{
              find(where: $where,orders:$orders){
                acvitityType
                banners
                company{
                  name
                }
                exhibitionId
                exhibitorId
                externalLink
                followCount
                id
                likeCount
                shareCount
                startTime
                endTime
                stateIndex
                title
                userCount
                liveAuditState
                hasLive
                liveStateIndex
                auditState
                topic
                topicIndex
                viewCount
              }
            }
          }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal3: {
          n: "isShow",
          v: 1,
        },
        greaterThan_topic: {
          n: "topic",
          v: 0,
        },
      };
      let order = [
        { n: "Topic", v: 0 },
        { n: "TopicIndex", v: 0 },
        { n: "StateIndex" },
        { n: "CreateAt", v: 0 },
      ];
      let opt = {
        query: query,
        variables: {
          where: where,
          orders: order,
        },
      };
      let data = await this.graphqlPostByZXSEn(opt);
      let res = JSON.parse(JSON.stringify(data.data.activityQuery.find));
      if (res && res.length) {
        res.map((c) => {
          if (c.banners && c.banners.search(/^\[/) !== -1) {
            c.banners = JSON.parse(c.banners);
          } else {
            c.banners = [c.banners];
          }
        });
      }
      this.swiperList = res || [];
      this.getActiveList();
    },
    async getExActive() {
      let query = `
            query getActivityExhibitions($exhibitionId: Guid!){
              getActivityExhibitions(exhibitionId: $exhibitionId){
                acvitityType
                banners
                createAt
                endTime
                exhibitionId
                exhibitorId
                externalLink
                followCount
                id
                likeCount
                shareCount
                startTime
                stateIndex
                title
                userCount
                videos
                liveAuditState
                hasLive
                liveStateIndex
                auditState
                viewCount
                address
                introduction
              }
            }
        `;
      let opt = {
        query: query,
        variables: {
          exhibitionId: this.exhibitionId,
        },
      };
      let data = await this.graphqlPostByZXSEn(opt);
      if (
        data.data.getActivityExhibitions &&
        data.data.getActivityExhibitions.length
      ) {
        let res = JSON.parse(JSON.stringify(data.data.getActivityExhibitions));
        let actIng = res.filter(
          (c) => c.hasLive === true && c.liveStateIndex === 1
        ); // 正在进行的活动
        let actBefore = res.filter(
          (c) => c.hasLive === true && c.liveStateIndex === 2
        ); // 未开始
        let actOver = res.filter(
          (c) => c.hasLive === true && c.liveStateIndex === 3
        ); //已结束
        actBefore.sort(function (a, b) {
          return (
            Date.parse(b.startTime.replace(/-/g, "/")) -
            Date.parse(a.startTime.replace(/-/g, "/"))
          );
        });
        actOver.sort(function (a, b) {
          return (
            Date.parse(b.startTime.replace(/-/g, "/")) -
            Date.parse(a.startTime.replace(/-/g, "/"))
          );
        });
        if (actIng.length) {
          this.exActList = actIng[0];
        } else {
          if (actBefore.length) {
            this.exActList = actBefore.reverse()[0];
          } else {
            if (actOver.length) {
              this.exActList = actOver[0];
            }
          }
        }
        const Today = this.$service.formatTime(new Date()).slice(0, 11);
        res.forEach((c) => {
          c.startTime = this.exhibitionInfo.startData;
          c.endTime = this.exhibitionInfo.endDate;
          let Time = c.startTime.slice(0, 11).replace(/-/g, "/");
          if (Today == Time) {
            this.exActList = c;
          }
        });
        if (!this.exActList) return;
        if (!this.exActList.banners) return;
        if (this.exActList.banners.indexOf("[") !== -1) {
          this.exActList.banners = JSON.parse(this.exActList.banners);
        } else {
          this.exActList.banners = [this.exActList.banners];
        }
        console.log(this.exActList, "主题活动数据");
      }
      this.getActiveList(); // 获取活动列表数据
    },
    async getActiveList() {
      this.dataLoading = true;
      let query = `
          query activityQuery($query:_Any!){ 
            activityQuery {
              page(query: $query) {
                hasNextPage
                hasPreviousPage
                pageIndex
                pageSize
                totalCount
                totalPages
                items{
                  acvitityType
                  banners
                  company{
                    name
                  }
                  exhibitionId
                  exhibitorId
                  externalLink
                  followCount
                  id
                  likeCount
                  shareCount
                  startTime
                  endTime
                  stateIndex
                  title
                  userCount
                  liveAuditState
                  hasLive
                  liveStateIndex
                  auditState
                  topic
                  topicIndex
                  viewCount
                  address
                  introduction
                }
              } 
            }
          }
      `;
      let EqualContent = {};
      if (this.searchType === "3") {
        EqualContent = this.searchTxt
          ? { Like: { n: "title", v: `%${this.searchTxt}%` } }
          : {};
      }
      let opt = {
        queryName: "activityQuery",
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: { ...this.where, ...EqualContent },
            order: this.order,
          },
        },
      };
      let { data } = await this.graphqlPostByZXSEn(opt);
      if (data.activityQuery.page) {
        let currentList = data.activityQuery.page.items;
        currentList.map((c) => {
          if (c.banners) {
            c.banners = JSON.parse(c.banners);
          }
        });
        this.ActList = currentList;
      } else {
        this.ActList = [];
      }
      this.dataLoading = false;
      this.totalCount = data.activityQuery.page.totalCount;
      if (!data.activityQuery.page.hasNextPage) {
        this.finished = true;
      }
    },
    selectTab(index) {
      this.$store.commit("setActivityPage", 1);
      this.pageIndex = 1;
      this.activeIndex = index;
      this.ActList = [];
      this.loading = false;
      this.finished = false;
      switch (index) {
        case 0: // 全部
          this.where = {
            Equal: {
              n: "exhibitionId",
              v: this.exhibitionId,
            },
            Equal2: {
              n: "isExhibitionActivity",
              v: 0,
            },
            Equal3: {
              n: "isShow",
              v: 1,
            },
          };
          this.order = [
            { n: "Topic", v: 0 },
            { n: "TopicIndex", v: 0 },
            { n: "StateIndex", v: 1 },
            { n: "startTime", v: 1 },
            { n: "endTime", v: 0 },
            { n: "ViewCount", v: 0 },
            { n: "CreateAt", v: 0 },
          ];
          break;
        case 1: // 进行中
          this.where = {
            Equal: {
              n: "exhibitionId",
              v: this.exhibitionId,
            },
            Equal2: {
              n: "stateIndex", // 1进行中 2未开始 3已结束
              v: 1,
            },
            Equal4: {
              n: "isExhibitionActivity",
              v: 0,
            },
            Equal5: {
              n: "isShow",
              v: 1,
            },
          };
          this.order = [
            { n: "LiveStateIndex", v: 1 },
            { n: "startTime", v: 1 },
            { n: "endTime", v: 0 },
            { n: "ViewCount", v: 0 },
            { n: "CreateAt", v: 0 },
          ];
          break;
        case 2: // 未开始
          this.where = {
            Equal: {
              n: "exhibitionId",
              v: this.exhibitionId,
            },
            Equal2: {
              n: "stateIndex",
              v: 2,
            },
            Equal4: {
              n: "isExhibitionActivity",
              v: 0,
            },
            Equal5: {
              n: "isShow",
              v: 1,
            },
          };
          this.order = [
            { n: "LiveStateIndex", v: 1 },
            { n: "startTime", v: 1 },
            { n: "endTime", v: 0 },
            { n: "ViewCount", v: 0 },
            { n: "CreateAt", v: 0 },
          ];
          break;
      }
      this.getActiveList();
    },
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getActiveList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.my-page {
  width: 100%;
  flex-shrink: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.activity {
  position: relative;
  min-height: 500px;

  &-live {
    position: relative;
    z-index: 2;
    display: flex;
    height: 540px;
    background-color: rgba(#000, 0.8);
    .al {
      &-l {
        width: 960px;
        height: 540px;
      }
      &-r {
        flex: 1;
        height: 540px;
        padding: 15px 0;
        position: relative;
        overflow: hidden;
      }
    }
  }
  &-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &.is_swiper {
      position: inherit;
    }
  }
  &-tab {
    margin: 20px 0;
    height: 48px;
    background-color: #fff;
    border: solid 1px #e5e5e5;
    display: flex;
    align-items: center;
    &-item {
      display: inline-block;
      min-width: 120px;
      background-color: #fff;
      position: relative;
      text-align: center;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -9px;
        right: 0;
        height: 19px;
        width: 1px;
        background-color: #e6e6e6;
      }
      &:last-child {
        &::before {
          background-color: transparent;
        }
      }
    }
  }
  &-list {
    .m-list5 {
      margin-bottom: 20px;
    }
  }
  .g-content {
    position: relative;
    z-index: 2;
  }
}

.my-swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  &.gallery {
    height: 100%;
    width: 100%;
    .swiper-slide {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.thumbs {
    height: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    .swiper-slide {
      width: 210px;
      height: 120px;
      opacity: 0.8;
      border-radius: 3px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .swiper-slide-active {
      opacity: 1;
      border: 3px solid #ff5e00;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -6px;
        left: -8px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 8px solid #ff5e00;
        border-bottom: 8px solid transparent;
      }
    }
  }
}
.no_more {
  margin: 0;
  padding: 20px 0 70px;
}
.l-banner-bot {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 42px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  span {
    display: inline-block;
    width: 50%;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span:last-child {
    text-align: right;
  }
}
// 隐藏滚动条
.hide-scrollbar {
  position: absolute;
  left: 0;
  top: 15px;
  right: -17px;
  bottom: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>